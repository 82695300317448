/* Webfonts: Copyright © Letters From Sweden.*/
@font-face {
  font-family: 'LabGrotesqueWeb';
  src:
    local('LabGrotesqueWeb'),
    url('./fonts/LabGrotesqueWeb-Regular.ttf') format('truetype');
}

/* Webfonts: Copyright © Letters From Sweden.*/
@font-face {
  font-family: 'LabGrotesqueWebMedium';
  src:
    local('LabGrotesqueWebMedium'),
    url('./fonts/LabGrotesqueWeb-Medium.ttf') format('truetype');
}

/* Webfonts: Copyright © Letters From Sweden.*/
@font-face {
  font-family: 'LabGrotesqueWebBold';
  src:
    local('LabGrotesqueWebBold'),
    url('./fonts/LabGrotesqueWeb-Bold.ttf') format('truetype');
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'LabGrotesqueWeb', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

th {
  font-weight: 700;
  background-color: #e9eef2;
}

.vertical-separator {
  width: 1px;
  height: 100%;
  background-color: gray;
}

.right-aligned {
  margin-left: auto;
}

h1 {
  font-size: 28px;
  font-weight: 300;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-center {
  align-items: center;
}

.justify-content-stretch {
  justify-content: stretch;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-space-between {
  justify-content: space-between;
}

.align-self-end {
  align-self: flex-end;
}

.flex-row-centered {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-gap-5 {
  gap: 5px;
}

.flex-gap-10 {
  gap: 10px;
}

.flex-gap-20 {
  gap: 20px;
}

.text-success {
  color: #009a96;
}

.text-danger {
  color: #d5121e;
}

.cursor-pointer {
  cursor: pointer;
}
