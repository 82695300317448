#organization {
  display: flex;
  flex-direction: column;
}

#organization .data-container {
  justify-content: space-between;
  gap: 30px;
}

#organization .data-container .info,
#organization .data-container .representatives,
#organization .data-container .quick-links {
  flex-grow: 1;
}

#organization .data-container .body {
  border-top: 1px solid #d5dde3;
  border-bottom: 1px solid #d5dde3;
  padding: 15px;
}

#organization .data-container .representatives .representative-row .indent {
  margin-left: 15px;
}
