.loader {
  display: flex;
  justify-content: center;
  align-self: center;
  margin-top: 15px;
}

.loader > div {
  width: 5px;
  height: 5px;
  margin: 0px 1px;
  border-radius: 50%;
  background-color: #d5121e;
  opacity: 1;
  animation: loader 0.6s infinite alternate;
}

@keyframes loader {
  to {
    opacity: 0.1;
    transform: translateY(-16px);
  }
}

.loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.loader > div:nth-child(3) {
  animation-delay: 0.4s;
}
