.profile-menu-container {
  height: 100%;
  display: flex;
}

.profile-menu-container .profile-menu-button {
  color: white;
  text-transform: none;
}

.profile-menu ul,
.profile-menu li {
  background-color: #009a96;
}

.profile-menu a {
  color: white;
}
