.custom-inline {
  gap: 8px;
}

.custom-link {
  gap: 8px;
}

.custom-list {
  gap: 8px;
}

.rdw-editor-toolbar > div {
  margin: 0;
}

.rdw-option-wrapper {
  height: 40px;
  width: 48px;
  border: 2px solid #d5121e;
}

.rdw-option-active {
  box-shadow: none;
  border: 2px solid #333333;
}

.rdw-dropdown-wrapper {
  height: 40px;
  padding: 8px 18px 8px 16px;
  border: 1px solid #6d838f;
}

.rdw-dropdown-carettoopen {
  top: 40%;
  left: 90%;
}

.rdw-dropdown-carettoclose {
  top: 40%;
  left: 90%;
}

.custom-blocktype {
  width: 160px;
}

.custom-fontsize {
  width: 72px;
}

.custom-fontfamily {
  width: 88px;
}

.rdw-option-disabled {
  border-color: #b3b3b3;
}
