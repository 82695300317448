.footer {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.footer > .general-info {
  padding-top: 10px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.footer-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.footer > .version-info {
  color: #6d838f;
  background-color: #dfe6eb;
}

.footer a {
  color: #d5121e;
}
